<script>
  import { storedSelectedCompany, storedSelectedTeam } from './store.js'

  export let units
  export let type
  export let label = ''
  export let firstSelectionLabel = ''

  const selectName = `${type}_id`
  const searchParams = new URLSearchParams(window.location.search)
  const selectedId = searchParams.get(selectName)

  let selected

  if ($storedSelectedCompany) {
    selected = $storedSelectedCompany
  }

  function handleChange(e) {
    if (e.target.value == 0) {
      searchParams.delete(selectName)
    } else {
      searchParams.set(selectName, selected)
    }

    window.location.search = searchParams.toString()
  }

  $: console.log(selectedId)
</script>

<div class="form-group form-group-sm">
  <div class="input-group">
    <div class="input-group-addon">
      {label}
    </div>
    <select
      name={selectName}
      id={selectName}
      class="form-control"
      bind:value={selected}
      on:change|preventDefault={handleChange}
    >
      <option value="0" selected={selectedId == null}
        >{firstSelectionLabel}</option
      >
      {#each units as unit}
        <option value={unit.id} selected={unit.id == selectedId}
          >{unit.name}</option
        >
      {/each}
    </select>
  </div>
</div>
