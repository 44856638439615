<script>
  import axios from 'axios'
  export let eventCategories = []

  $: activeEventCategories = eventCategories.filter(
    (eventCategory) => eventCategory.active,
  )

  $: deactivatedEventCategories = eventCategories.filter(
    (eventCategory) => eventCategory.active === false,
  )
</script>

<svelte:head>
  <title>Hændelseskategorier</title>
</svelte:head>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <a href="/event_categories/new" class="btn btn-xl btn-primary"
      >Opret ny hændelseskategori</a
    >
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h1>Hændelseskategorier</h1>
    <h4>Aktive kategorier</h4>
    <table class="table">
      <thead>
        <tr>
          <th>Navn</th>
          <th class="text-center">Registeringer</th>
          <th class="text-right">Handlinger</th>
        </tr>
      </thead>
      <tbody>
        {#each activeEventCategories as eventCategory (eventCategory.id)}
          <tr>
            <td
              ><a href="/event_categories/{eventCategory.id}"
                >{eventCategory.name}</a
              ></td
            >
            <td class="text-center">{eventCategory.events_count}</td>
            <td class="text-right">
              <a
                href="/event_categories/{eventCategory.id}/edit"
                class="btn btn-default">Rediger</a
              >
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
    {#if deactivatedEventCategories.length > 0}
      <h4>Deaktiverede kategorier</h4>
      <table class="table">
        <thead>
          <tr>
            <th>Navn</th>
            <th class="text-center">Registeringer</th>
            <th class="text-right">Handlinger</th>
          </tr>
        </thead>
        <tbody>
          {#each deactivatedEventCategories as eventCategory (eventCategory.id)}
            <tr>
              <td
                ><a href="/event_categories/{eventCategory.id}"
                  >{eventCategory.name}</a
                ></td
              >
              <td class="text-center">{eventCategory.events_count}</td>
              <td class="text-right">
                <a
                  href="/event_categories/{eventCategory.id}/edit"
                  class="btn btn-default">Rediger</a
                >
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/if}
  </div>
</div>
